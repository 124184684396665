Vue.component('wheellogo', {
  template: `
  <div class="wheellogo">
    <img :src="fullurl" @error="png = true"></img>
  </div>
  `,
  data: function () {
    return {
      basePath: '/skin/frontend/mediadivision/oz/img/wheelicons/logo_',
      png: false
    }
  },
  props: [
    'manufacturer'
  ],
  computed: {
    'fullurl': function() {
      var ext = this.png?'.png':'.svg';
      return this.basePath + this.manufacturer.toLowerCase() + ext;
    }
  },
  mounted: function() {
    // Lazy Loading
  }
})